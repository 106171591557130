import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'

import Headline from '../elements/headline'
import Paragraph from '../elements/paragraph'

const PodlovePagedFeeds = () => (
  <Layout
    hero={[
      'Podlove Paged Feeds',
      'This document explains the reasoning behind our recommendation to create paged feeds by podcasters and to provide support for paged feeds in podcast clients.'
    ]}
  >
    <Seo title="Podlove Paged Feeds" />
    <div
      className="
        flex
        flex-wrap
        items-stretch
        ml-auto
        mr-auto
        my-16
        max-w-full
        md:max-w-2xl
        px-4
      "
    >
      <Paragraph>This is not a format specification. Instead, we are defining best practice here and point to the IETF specification <a className="text-turquoise-500 font-semibold" href="https://datatracker.ietf.org/doc/html/rfc5005" target="_blank" rel="noreferrer">RFC 5005</a>.</Paragraph>
      <Headline level={2}>The problem with feed size</Headline>
      <Paragraph>Podcasts are communicated to the user by the use of <b>podcast feeds</b>. These feeds, usually encoded in <a className="text-turquoise-500 font-semibold" href="https://cyber.harvard.edu/rss/rss.html" target="_blank" rel="noreferrer">RSS 2.0</a> format, syndicate content by listing news items and are periodically checked by interested parties. This concept is known as polling.</Paragraph>
      <Paragraph>The more parties are interested in the content, the more polling occurs and increases the overall network load on the server. Also, the bigger the feed grows, the more load is being generated both on the server and the client who needs to parse the content of the feed to check for new content. This is especially problematic for mobile equipment that needs to save battery power and has less computing power available compared with desktop machines.</Paragraph>
      <Paragraph>In addition to that, popular feed proxy services like FeedBurner have certain feed size limitations. If a feed grows too big, they stop processing it, eventually shutting down the service. In order to prevent this from happening, the feed size must not grow beyond this limit.</Paragraph>
      <Paragraph>This has lead to the standard practice of just including the most recent items in the feed,  kicking out entries that point to older podcast episodes.</Paragraph>
      <Paragraph>While old feed items are not needed to check for the latest content, people sometimes explicitly want to access older episodes or even go back and review the whole archive. Podcasters sometimes present archive pages on their websites, but most people prefer to use the same tool – their favorite podcast client – to access these episodes as they do it with the latest episodes.</Paragraph>
      <Paragraph>This is where Paged Feeds help.</Paragraph>
      <Headline level={2}>Using Paged Feeds</Headline>
      <Paragraph>The <a className="text-turquoise-500 font-semibold" href="https://datatracker.ietf.org/doc/html/rfc5005" target="_blank" rel="noreferrer">RFC 5005</a> defines two new methods of how to present feeds to clients:</Paragraph>
      <ul
        className="
          list-disc
          list-inside
          md:list-outside
          space-y-4
          pb-6
          max-w-full
        "
      >
        <li>Paged Feeds</li>
        <li>Archive Feeds</li>
      </ul>
      <Paragraph>While the usefulness of Archive Feeds for podcast feeds remains to be discussed, we found that Paged Feeds actually provide an appropriate and easy to use solution for the problem of providing access to older episodes without increasing feed size for entities checking just the the latest information.</Paragraph>
      <Paragraph>Once a podcast distributes feed content over multiple pages, using the specification mentioned above, the clients still get small subscription feeds but clients interested in the whole archive can get to this easily by reading additional pages until they find the content they were looking for or get a complete archive.</Paragraph>
      <Paragraph>Interestingly, this is also compatible with feed proxy sites like Feed Burner because the first page still serves as a valid subscription feed whose size can be kept to a minimum and the service leaves the link to the next page in so that informed clients can still access the additional items.</Paragraph>
      <Paragraph>We encourage both developers of podcast CMS and podcast clients to include support for Paged Feeds as specified by RFC 5005. It does not add much complexity to either side but enhances the user experience for podcast consumers significantly.</Paragraph>
    </div>
  </Layout>
)

export default PodlovePagedFeeds
